import React from "react";
import cx from "classnames";
import { graphql } from "gatsby";

import get from "lodash/get";
import Layout from "../components/layout";
import Seo from "../components/seo/seo";
import Hero from "../components/hero/hero";
import { URLS } from "../config/urls";
import ScrollSection from "../components/scroll-section/scroll-section";
import HoverItem from "../components/hover-item/hover-item";

class BlogIndex extends React.Component {
  render() {
    const continents = get(this, "props.data.allContentfulContinent.edges");
    const countries = get(this, "props.data.allContentfulCountry.edges");

    return (
      <Layout
        location={this.props.location}
        continents={continents}
        countries={countries}
        status={this.props.transitionStatus}
      >
        <Seo
          type="page"
          content={{
            title: "Travel Destinations",
            seoDescription: "Travel Destinations",
          }}
        />
        <ScrollSection>
          <Hero
            title={"Destinations".toUpperCase()}
            border={false}
            subtitle="Browse the travel archives by destination:"
          />
        </ScrollSection>
        <ScrollSection>
          <div className={cx("p-0 static-list")}>
            <h2
              className={cx(
                "text-black leading-none uppercase static-list__title"
              )}
            >
              Continents
            </h2>
            <ul className={cx("p-0")}>
              {continents.map(({ node }) => {
                const url = `${URLS.Archive}${node.slug}/`;
                return (
                  <HoverItem
                    key={node.slug}
                    node={node}
                    url={url}
                    classNames={{
                      link: "my-5 block leading-none relative uppercase hover:text-red-accessible",
                      listItem: "relative",
                    }}
                  />
                );
              })}
            </ul>
          </div>
        </ScrollSection>
        <ScrollSection>
          <div className={cx("p-0 static-list")}>
            <h2
              className={cx(
                "text-black leading-none uppercase static-list__title"
              )}
            >
              Countries
            </h2>
            <ul className={cx("p-0")}>
              {countries.map(({ node }) => {
                const url = `${URLS.Archive}${node.continent.slug}/${node.slug}/`;
                return (
                  <HoverItem
                    key={node.slug}
                    node={node}
                    url={url}
                    classNames={{
                      link: "my-5 block leading-none relative uppercase hover:text-red-accessible",
                      listItem: "relative",
                    }}
                  />
                );
              })}
            </ul>
          </div>
        </ScrollSection>
      </Layout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query TravelIndexQuery {
    allContentfulContinent(sort: { fields: [title], order: ASC }) {
      edges {
        node {
          title
          slug
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              width: 424
              height: 212
            )
          }
        }
      }
    }
    allContentfulCountry(sort: { fields: [title], order: ASC }) {
      edges {
        node {
          title
          slug
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              width: 424
              height: 212
            )
          }
          continent {
            title
            slug
          }
        }
      }
    }
  }
`;
